<template>
  <div class="mb-5">
    <form @submit.prevent='submitEmployee' class="narrow-form">
      <div class="form-field mb-3 switcher-container">
        <label class="switcher" :class="{disabled: employee.blockedAt}">
          <input type="checkbox" v-model="allBusinessesAccess" :disabled="employee.blockedAt">
          <span class="caption">All businesses</span>
          <i class="toogle-switch" />
        </label>
      </div>

      <div class="form-field mb-2">
        <Multiselect
          v-model="businesses"
          :options="businessesOptions"
          :max-height="540"
          :limit="300"
          :options-limit="300"
          :allow-empty="false"
          :disabled="allBusinessesAccess || employee.blockedAt"
          :multiple="true"
          track-by="id"
          label="name"
          placeholder="Pick a businesses">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
            <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].name }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <div>
              <span class="d-block text-truncate">{{ props.option.name }}</span>
              <span class="d-block font-size-14 text-truncate">{{ props.option.address }}</span>
              <span class="d-block font-size-14 text-truncate">{{ props.option.storeCode }}</span>
            </div>
          </template>
        </Multiselect>
      </div>

      <div :class="['mv-list-tag', { disabled: allBusinessesAccess || employee.blockedAt }]">
        <div v-for="(business, index) in businesses" class="mv-list-tag-item">
          {{ business.name }}
          <i class="far fa-times icon" @click="removeBusiness(index)" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-field mb-3">
            <label class="mb-0">Name<span class="text-danger">*</span></label>
            <input
              v-model.trim="name"
              type="text"
              required="true"
              placeholder="Enter name"
              :disabled="employee.blockedAt">
            <span v-if="handleErrors('name')" class="text-danger">{{ handleErrors('name') }}</span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field mb-3">
            <label class="mb-0">Last name<span class="text-danger">*</span></label>
            <input
              v-model.trim="lastName"
              type="text"
              required="true"
              placeholder="Enter last name"
              :disabled="employee.blockedAt">
            <span v-if="handleErrors('lastName')" class="text-danger">{{ handleErrors('lastName') }}</span>
          </div>
        </div>
      </div>

      <div class="form-field mb-3">
        <label class="mb-0">Email<span class="text-danger">*</span></label>
        <input
          v-model="email"
          type="email"
          required="true"
          placeholder="Enter email"
          :disabled="employee.blockedAt">
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="handleErrors('email')" class="text-danger" v-html="handleErrors('email')" />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <div class="form-field mb-3">
        <label class="mb-0">Additional</label>
        <textarea v-model="description"
          rows="4"
          id="description"
          placeholder="Describe additional information"
          :disabled="employee.blockedAt" />
      </div>

      <div class='d-flex'>
        <div class="form-field switcher-container" data-toggle="tooltip" :data-original-title="employee.blockedAt ? null : modetationHint">
          <label class="switcher" :class="{disabled: employee.blockedAt}">
            <input type="checkbox" v-model="moderationEnabled" :disabled="employee.blockedAt">
            <span class="caption">
              <i class="far fa-info-circle text-primary" />
              Pre-moderation
            </span>
            <i class="toogle-switch" />
          </label>
        </div>
      </div>
      <div class='d-flex'>
        <div id="skip-invitation" v-if="!employee.id" class="form-field switcher-container">
          <label class="switcher">
            <input type="checkbox" v-model='skipInvitation'>
            <span class="caption">
              <i class="far fa-info-circle text-primary" />
              Skip invitation
            </span>
            <i class="toogle-switch" />
          </label>
        </div>
        <b-tooltip target="skip-invitation" noninteractive>{{ skipInvitationHints[skipInvitation] }}</b-tooltip>
      </div>

      <div :class="['row', { invisible: !skipInvitation || employee.id }]">
        <div class="col-12 col-md-6">
          <div class="form-field mb-3">
            <label class="mb-0">Password<span class="text-danger">*</span></label>
            <input
              v-model.trim="password"
              type="password"
              :required="skipInvitation && !employee.id"
              placeholder="Enter password"
              :disabled="employee.blockedAt">
            <span class='small text-danger' v-if="handleErrors('password')">{{ handleErrors('password') }}</span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-field mb-3">
            <label class="mb-0">Password confirmation<span class="text-danger">*</span></label>
            <input
              v-model.trim="passwordConfirmation"
              type="password"
              :required="skipInvitation && !employee.id"
              :disabled="employee.blockedAt"
              placeholder="Confirm password">
            <span class='small text-danger' v-if="handleErrors('passwordConfirmation')">
              {{ handleErrors('passwordConfirmation') }}
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex pt-2">
        <button class="btn btn-primary rounded mr-3" type="submit" :disabled="isRequestSent">
          <template v-if="employee.id">
            Save
          </template>
          <template v-else-if='skipInvitation'>
            Add employee
          </template>
          <template v-else>
            Invite
          </template>
        </button>
      </div>
    </form>
    <template v-if="employee.id">
      <div class="mv-employee-actions">
        <span class="d-block h4 font-weight-semi-bold text-dark mb-3">Actions</span>
        <div class="d-flex flex-wrap gap-4">
          <button :disabled="isRequestSent || !employee.invitationAcceptedAt" type="button" class="btn rounded" @click="sendResetPassword">
            <i class="far fa-user-shield mr-2" />
            Reset password
          </button>

          <button :disabled="isRequestSent || employee.invitationAcceptedAt || !employee.invitationSentAt || employee.blockedAt"
            type="button" class="btn rounded" @click="resendInvitation">
            <i class="far fa-envelope-open-text mr-2" />
            Resend invitation
          </button>

          <button :disabled="isRequestSent" type="button" class="btn rounded" @click="toggleBlockedAtForEmployee">
            <i class="far fa-user-times mr-2" />
            {{ isEmployeeBlocked ? 'Unblock' : 'Block' }} user
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axiosTransform from '../../../../common/axios'
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'

export default {
  components: { Multiselect },
  props: {
    businessesOptions: Array,
    employee: Object,
    currentBusiness: Object
  },
  data() {
    return {
      allBusinessesAccess: this.employee.allBusinessesAccess,
      businesses: this.selectedBusinesses(),
      name: this.employee.name,
      lastName: this.employee.lastName,
      email: this.employee.email,
      description: this.employee.description,
      moderationEnabled: this.employee.moderationEnabled,
      skipInvitation: false,
      skipInvitationHints: {
        true: 'Set password manually, employee will be able to log in immediately',
        false: 'Employee will have to accept an invitation from the email'
      },
      password: null,
      passwordConfirmation: null,
      isRequestSent: false,
      isEmployeeBlocked: Boolean(this.employee.blockedAt),
      errors: {}
    }
  },
  created() {
    this.modetationHint = 'When enabled, all moments will need to be reviewed and approved ' +
                'from the web application. When disabled, all moments will publish immediately ' +
                'to GBP and the WordPress plugin.'
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    selectedBusinesses() {
      if (this.employee.businesses.length > 0) {
        return this.employee.businesses
      }

      return [this.currentBusiness]
    },
    removeBusiness(index) {
      if (this.businesses.length > 1) {
        this.businesses.splice(index, 1)
      }
    },
    handleErrors(key) {
      let errorText = this.errors[key]?.join(', ')
      if (this.errors[`${key}Additional`]) {
        errorText += this.errors[`${key}Additional`].join(', ')
      }

      return errorText
    },
    submitEmployee() {
      this.isRequestSent = true

      const prms = {
        mobileEmployee: {
          skipInvitation: this.skipInvitation,
          name: this.name,
          lastName: this.lastName,
          email: this.email,
          description: this.description,
          moderationEnabled: this.moderationEnabled,
          allBusinessesAccess: this.allBusinessesAccess,
          businesses: this.businesses.map(({ id }) => id)
        }
      }

      if (this.employee.id) {
        this.updateEmployee(prms)
      } else {
        if (this.skipInvitation) {
          prms.mobileEmployee.password = this.password
          prms.mobileEmployee.passwordConfirmation = this.passwordConfirmation
        }
        this.createEmployee(prms)
      }
    },
    updateEmployee(data) {
      return axios.patch(`/gb/ajax/employees/${this.employee.id}`, data, axiosTransform)
        .then(() => {
          toastr.success('Employee updated successfully')
          window.location = `/gb/businesses/${this.currentBusiness.id}/employees`
        }).catch((res) => {
          this.errors = res.response.data.errors
          this.isRequestSent = false
        })
    },
    createEmployee(data) {
      return axios.post('/gb/ajax/employees', data, axiosTransform)
        .then(() => {
          toastr.success('Employee created successfully')
          window.location = `/gb/businesses/${this.currentBusiness.id}/employees`
        }).catch((res) => {
          this.errors = res.response.data.errors
          this.isRequestSent = false
        })
    },
    toggleBlockedAtForEmployee() {
      this.isRequestSent = true

      const prms = {
        mobileEmployee: {
          blockedAt: this.employee.blockedAt ? null : new Date(),
          businesses: this.employee.businesses.map(({ id }) => id)
        }
      }

      return axios.patch(`/gb/ajax/employees/${this.employee.id}`, prms, axiosTransform)
        .then(() => {
          toastr.success(`Employee ${this.isEmployeeBlocked ? 'un' : ''}blocked successfully`)
          this.isEmployeeBlocked = !this.isEmployeeBlocked
          // eslint-disable-next-line vue/no-mutating-props
          this.employee.blockedAt = this.isEmployeeBlocked ? prms.mobileEmployee.blockedAt : null
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
        .finally(() => {
          this.isRequestSent = false
        })
    },
    resendInvitation() {
      this.isRequestSent = true

      return axios.post(`/gb/ajax/employees/${this.employee.id}/resend_invitation`)
        .then(() => {
          toastr.success('Invitation sent successfully')
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.isRequestSent = false
        })
    },
    sendResetPassword() {
      return axios.get(`/gb/ajax/employees/${this.employee.id}/send_reset_password_instruction`)
        .then(() => {
          toastr.success('Reset password sent successfully')
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.isRequestSent = false
        })
    }
  }
}
</script>
